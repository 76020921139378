*{
  margin:0;
  padding:0;
}

.navImage{
  background: url('https://images.unsplash.com/photo-1574283275608-f60f29549194?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1065&q=80')
  no-repeat center;
  background-position: cover;
  height: 300px;
}

nav{
  background-color: cadetblue;
  text-align: center;
  margin-bottom: 20px;
}

nav ul{
  list-style-type: none;
}

nav li{
  display: inline-block;
  padding: 20px;
}

nav li:hover{
  background-color: darkolivegreen;
}